import { cva, cx } from 'cva';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ESolidTabSize, ESolidTabTheme } from './SolidTab.constants';

export type TSolidTabProps = {
  value: string;
  label: string;
  size?: ESolidTabSize;
  theme?: ESolidTabTheme;
  onClick: (value: string) => void;
  isActive?: boolean;
  isDisable?: boolean;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
};

const tabOptionCVA = cva(
  'group flex outline-none flex-row gap-2.5 snap-center items-center rounded-[2rem] border-2 whitespace-nowrap',
  {
    variants: {
      size: {
        [ESolidTabSize.Small]:
          'text-sm font-medium leading-normal px-[0.875rem] py-[0.375rem] h-8',
        [ESolidTabSize.Medium]:
          'text-base font-medium leading-loose px-[1.4rem] py-[0.875rem] h-12',
        [ESolidTabSize.Large]:
          'text-base font-medium leading-relaxed px-[1.875rem] py-[0.875rem] h-[3.25rem]',
        [ESolidTabSize.Outline]:
          'py-2.5 px-6 text-base font-medium leading-loose h-10',
      },
      theme: {
        [ESolidTabTheme.Ordinary]: '',
        [ESolidTabTheme.Sticked]: '',
      },
      isActive: {
        true: '',
        false: '',
      },
      isDisable: {
        true: 'opacity-40',
        false: '',
      },
    },
    compoundVariants: [
      {
        isActive: false,
        theme: ESolidTabTheme.Ordinary,
        className:
          'border-transparent text-control-950 hover:text-control-1000 focus:text-control-1000 will-change-transform',
      },
      {
        isActive: true,
        theme: ESolidTabTheme.Ordinary,
        className: 'border-control-1000 text-control-1000',
      },
      {
        isActive: false,
        theme: ESolidTabTheme.Sticked,
        className:
          'border-control-1000 bg-control-1000 text-control-50/90 hover:text-control-50 focus:text-control-50',
      },
      {
        isActive: true,
        theme: ESolidTabTheme.Sticked,
        className: 'border-control-1000 bg-control-50 text-control-1000',
      },
      {
        isActive: false,
        theme: ESolidTabTheme.Ordinary,
        size: ESolidTabSize.Outline,
        className:
          'border-surface-200 border-2 text-control-950 hover:text-control-1000',
      },
    ],
    defaultVariants: {
      isActive: false,
      isDisable: false,
    },
  },
);

const iconCVA = cva('flex items-center', {
  variants: {
    size: {
      [ESolidTabSize.Small]: 'h-4 w-4',
      [ESolidTabSize.Medium]: 'h-5 w-5',
      [ESolidTabSize.Large]: 'h-5 w-5',
      [ESolidTabSize.Outline]: 'h-5 w-5',
    },
    theme: {
      [ESolidTabTheme.Ordinary]: '',
      [ESolidTabTheme.Sticked]: '',
    },
    isActive: {
      true: 'text-control-950',
      false: '',
    },
    isDisable: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      isActive: false,
      isDisable: false,
      theme: ESolidTabTheme.Ordinary,
      className: 'text-control-950 opacity-30 group-hover:opacity-100',
    },
    {
      isActive: false,
      isDisable: false,
      theme: ESolidTabTheme.Sticked,
      className: 'text-control-950 invert opacity-60 group-hover:opacity-100',
    },
  ],
  defaultVariants: {
    theme: ESolidTabTheme.Ordinary,
    isActive: false,
  },
});

export const SolidTab: React.FC<TSolidTabProps> = props => {
  const {
    value,
    label,
    isActive = false,
    isDisable = false,
    onClick,
    theme = ESolidTabTheme.Ordinary,
    size = ESolidTabSize.Medium,
    leadingIcon,
    trailingIcon,
  } = props;

  return (
    <button
      key={value}
      disabled={isDisable}
      className={twMerge(tabOptionCVA({ theme, size, isActive, isDisable }))}
      role='tab'
      type='button'
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={() => onClick(value)}
    >
      {!!leadingIcon && (
        <i className={cx(iconCVA({ theme, isActive, size, isDisable }))}>
          {leadingIcon}
        </i>
      )}
      <span>{label}</span>
      {!!trailingIcon && (
        <i className={cx(iconCVA({ theme, isActive, size, isDisable }))}>
          {trailingIcon}
        </i>
      )}
    </button>
  );
};
